import { FC, useState, useRef, useEffect, Fragment } from 'react';
import { Card, CardContent, Button, Collapse, useTheme } from '@mui/material';
import {
  RemoveCircleOutline,
  AddCircleOutline,
  Visibility,
  GetApp,
} from '@mui/icons-material';
import Overlay from '../../pge-overlay/_Overlay';
import RichText from '../../rich-text';
import PgeButton from '../../pge-button/_PgeButton';
import { Theme } from '@mui/material/styles';
import { Properties } from 'csstype';
import { Shadow } from '../../../constants';
import { WrapperImageProps } from './types.d';

interface ImageProps extends WrapperImageProps {
  readonly imageHeight?: number;
  readonly imageRatio?: number;
}
const img: Properties = {
  display: 'block',
  width: '100%',
  maxWidth: 'max-content',
  margin: 'auto',
  fontSize: 0,
  height: '100%',
};


const WrapperImage: FC<WrapperImageProps> = ({
  image,
  imageHeader,
  imageIntroCopy,
  imageCaption,
  imageAltText,
  imageLongDescription,
  imageDisplayOptions,
  downloadFile,
  entryName,
  imageAlign = 'Center',
  largerImage,
  mobileImage,
}) => {
  // Vars
  const mobileFile = mobileImage?.file;
  const imageHeight = mobileFile?.details?.image?.height ?? 1200;
  const imageWidth = mobileFile?.details?.image?.width ?? 360;
  const imageRatio = Math.floor((imageHeight / imageWidth) * 100);

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const { palette, breakpoints, spacing, typography } = theme;
  const text = palette.text.primary;
  const lightGrey = palette.grey[100];
  const grey = palette.grey[300];
  const { paper } = palette.background;

  const largeImage: string = largerImage?.file?.url || '';
  const downloadAsset: string = downloadFile?.file?.url || '';
  const downloadIcon: boolean = !!imageDisplayOptions?.includes('Download');
  const viewIcon: boolean = !!imageDisplayOptions?.includes('ViewLarger');
  const detailsIcon: boolean = !!imageDisplayOptions?.includes('Details');

  const $img = useRef<HTMLDivElement>(null);
  const $btn = useRef<HTMLButtonElement>(null);
  const [expandImage, setExpandImage] = useState(false);
  const toggleDetails = (): void => setExpanded(!expanded);

  useEffect(() => {
    if (expandImage) {
      $img.current?.classList.add('isExpanded');
      $btn.current?.setAttribute('disabled', '');
    }
  }, [expandImage]);

  const content = (): JSX.Element => {
    return (
      <picture css={{
        width: '100%',
        marginTop: spacing(1.5),

        '& img': {
          ...img,
          borderRadius: spacing(0.875),
          boxShadow: Shadow.ONE,
        },
      }}>
        {mobileFile && (
          <Fragment>
            <source media="(max-width:767px)" srcSet={mobileFile?.url || undefined} />
            <source media="(min-width:768px)" srcSet={largeImage} />
          </Fragment>
        )}
        <img src={largeImage} alt="" />
      </picture>
    );
  };

  return (
    <article
      className={'WrapperImage'}
      css={{
        marginTop: spacing(4),

        [breakpoints.up('sm')]: {
          marginTop: spacing(8),
        },

        '& img': { ...img },
      }}
    >
      <Overlay
        entryName={entryName}
        html={content()}
        open={open}
        setOpen={setOpen}
      />
      <header
        css={{
          margin: 0,
          color: text,
          fontSize: spacing(3),
          lineHeight: typography.pxToRem(28),
          fontWeight: 100,

          [breakpoints.up('sm')]: {
            gridColumn: 'span 1',
            alignSelf: 'end',
            fontSize: spacing(4.5),
            lineHeight: typography.pxToRem(40),

            ...(imageAlign === 'Center' ? {
              textAlign: 'center',
            } : {}),

            ...((imageAlign === 'Left' || imageAlign === 'Right') ? {
              gridTemplateColumns: '1fr 1fr',
            } : {}),
          },
        }}
      >
        {imageHeader && (
          <h3 css={{
            margin: 0,
            color: text,
            fontSize: spacing(3),
            lineHeight: typography.pxToRem(28),
            fontWeight: 100,
            [breakpoints.up('sm')]: {
              gridColumn: 'span 1',
              alignSelf: 'end',
              fontSize: spacing(4.5),
              lineHeight: typography.pxToRem(40),

              ...(imageAlign === 'Center' ? {
                textAlign: 'center',
              } : {}),
            },
          }}>
            {imageHeader}
          </h3>
        )}
        {<RichText css={{
          marginTop: spacing(1.5),

          [breakpoints.up('sm')]: {
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'center',
            gridColumn: 'span 1',
          },

          '& > *': {
            maxWidth: 900,
          },
        }}>{imageIntroCopy}</RichText>}
        {image?.file?.url && (
          <div
            css={{
              ...(mobileFile ? {
                '&.isExpanded': {
                  paddingTop: `${imageRatio}%`,

                  '&::after': {
                    opacity: 0,
                    transition: '.2s',
                  },

                  [breakpoints.up('sm')]: {
                    paddingTop: 0,
                  },
                },

                display: 'flex',
                width: '100%',
                height: 'auto',
                paddingTop: 400,
                position: 'relative',
                marginTop: spacing(2.5),
                order: 2,
                overflow: 'hidden',
                justifyContent: 'center',
                backgroundColor: paper,
                fontSize: 0,
                transform: 'translateZ(0)',
                transition: 'padding .5s',

                [breakpoints.up('sm')]: {
                  marginTop: spacing(4),
                  paddingTop: 0,
                },

                '&::after': {
                  display: 'block',
                  width: '100%',
                  height: '40%',
                  position: 'absolute',
                  bottom: 0,
                  content: '""',
                  zIndex: 1,
                  background: 'linear-gradient(transparent, #fff) no-repeat',

                  [breakpoints.up('sm')]: {
                    content: 'none',
                  },
                },

                '& picture': {
                  display: 'block',
                  width: '100%',
                },

                '& img': {
                  display: 'block',
                  width: '100%',
                  height: 'auto',
                  position: 'absolute',
                  top: 0,
                  left: 0,

                  [breakpoints.up('sm')]: {
                    position: 'relative',
                  },
                },
              } : {})
            }}
            ref={$img}
          >
            <picture
              css={{
                ...(!mobileFile ? {
                  display: 'flex',
                  marginTop: spacing(1.5),

                  [breakpoints.up('sm')]: {
                    '.align-Center &': {
                      marginTop: spacing(2),
                    },

                    '.align-Left &, .align-Right &': {
                      gridRow: '1 / 3',
                      margin: 0,
                    },

                    '.align-Left &': {
                      gridColumn: '1 / 2',
                    },

                    '.align-Right &': {
                      gridColumn: '2 / 3',
                    },
                  },

                  '& img': {
                    borderRadius: spacing(0.875),
                    boxShadow: Shadow.ONE,
                  },
                } : {})
              }}
            >
              {mobileFile && (
                <Fragment>
                  <source media="(max-width:767px)" srcSet={mobileFile?.url || undefined} />
                  <source media="(min-width:768px)" srcSet={image.file.url} />
                </Fragment>
              )}
              <img
                src={image.file.url}
                alt={imageAltText?.imageAltText || undefined}
                width="700"
                height="500"
              />
            </picture>
            {mobileFile && (
              <PgeButton
                css={{
                  position: 'absolute',
                  bottom: spacing(2),
                  zIndex: 2,

                  '.isExpanded &': {
                    opacity: 0,
                    transition: 'opacity .2s',
                  },

                  [breakpoints.up('sm')]: {
                    'button&': { display: 'none' },
                  },
                }}
                theme="Pill"
                startIcon="Plus"
                onClick={() => setExpandImage(true)}
                ref={$btn}
              >
                Show more
              </PgeButton>
            )}
          </div>
        )}
      </header>
      {imageCaption && (
        <RichText css={{
          marginTop: spacing(2),
          color: text,
          fontSize: spacing(2),

          [breakpoints.up('sm')]: {
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'center',
            textAlign: 'center',
          },

          '& p': {
            marginTop: spacing(2),
            maxWidth: 900,
          },

          '& img': {
            maxWidth: 600,
            margin: spacing(2, 'auto', 0),
          },
        }}>{imageCaption}</RichText>
      )}
      {imageDisplayOptions && (
        <menu css={{
          display: 'flex',
          padding: 0,
          margin: spacing(3, 0, 0),
          position: 'relative',
          justifyContent: 'center',
        }}>
          {downloadIcon && downloadAsset && (
            <Button
              component={'a'}
              color="primary"
              size="large"
              href={downloadAsset}
              target="_blank"
              rel="noopener noreferrer"
              css={{
                minWidth: '9ch',
                padding: spacing(1, 3),
                boxSizing: 'border-box',
                fontSize: spacing(2),
                borderRight: '1px solid currentColor',
                borderRadius: 0,

                '&:last-child': {
                  borderRight: 'none',
                },

                [breakpoints.down('sm')]: {
                  padding: spacing(1, 0),

                  '& .MuiButton-label': {
                    flexDirection: 'column-reverse',
                  },

                  '& .MuiButton-endIcon': {
                    marginLeft: spacing(0),
                  },
                },
              }}
              endIcon={<GetApp />}
            >
              Download
            </Button>
          )}
          {viewIcon && largeImage && (
            <Button
              size="large"
              color="primary"
              css={{
                minWidth: '9ch',
                padding: spacing(1, 3),
                boxSizing: 'border-box',
                fontSize: spacing(2),
                borderRight: '1px solid currentColor',
                borderRadius: 0,

                '&:last-child': {
                  borderRight: 'none',
                },

                [breakpoints.down('sm')]: {
                  padding: spacing(1, 0),

                  '& .MuiButton-label': {
                    flexDirection: 'column-reverse',
                  },

                  '& .MuiButton-endIcon': {
                    marginLeft: spacing(0),
                  },
                },
              }}
              endIcon={<Visibility />}
              onClick={() => setOpen(true)}
            >
              View
            </Button>
          )}
          {detailsIcon && (
            <Button
              size="large"
              color="primary"
              css={{
                minWidth: '9ch',
                padding: spacing(1, 3),
                boxSizing: 'border-box',
                fontSize: spacing(2),
                borderRight: '1px solid currentColor',
                borderRadius: 0,

                '&:last-child': {
                  borderRight: 'none',
                },

                [breakpoints.down('sm')]: {
                  padding: spacing(1, 0),

                  '& .MuiButton-label': {
                    flexDirection: 'column-reverse',
                  },

                  '& .MuiButton-endIcon': {
                    marginLeft: spacing(0),
                  },
                },
                ...(expanded ? {
                  '&': {
                    background: lightGrey,
                    borderRadius: spacing(0.625, 0.625, 0, 0),
                    border: `1px solid ${grey}`,
                    borderBottom: 'none',

                    '&:hover': {
                      background: lightGrey,
                    },
                  },
                } : {})
              }}
              onClick={toggleDetails}
              aria-expanded={expanded}
              endIcon={
                expanded ? <RemoveCircleOutline /> : <AddCircleOutline />
              }
            >
              Details
            </Button>
          )}
        </menu>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent css={{
          padding: spacing(0),
        }}>
          <Card css={{
            background: lightGrey,
            border: `1px solid ${grey}`,
            borderRadius: spacing(0.625),
            marginTop: spacing(-0.25),
          }}>
            <RichText css={{
              padding: spacing(2),
              textAlign: 'left',

              '& img': {
                marginTop: spacing(2),
              },
            }}>
              {imageLongDescription}
            </RichText>
          </Card>
        </CardContent>
      </Collapse>
    </article>
  );
};

export default WrapperImage;
